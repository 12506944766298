import { Link } from "gatsby"
import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import TrustSection from "../components/sections/trustSection"
import HeadingGroup from "../components/ui/headingGroup"
import Banner from "../components/ui/banner"
import GetStartedSection from "../components/sections/getStartedSection"
import RequestDemoDrawer from "../components/drawers/requestDemoDrawer";
import SignUpDrawer from "../components/drawers/signUpDrawer";
import PricingDrawer from "../components/drawers/pricingDrawer";
import PageBreak from "../components/ui/pageBreak";
import ImageGroup from "../components/ui/imageGroup";
import styled from "styled-components";
import PrimaryButton from "../components/ui/primaryButton";

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

const ManufacturingPage = () => {
  const [openPriceCalculator, setOpenPriceCalculator] = useState(false);
  const [openRequestDemo, setOpenRequestDemo] = useState(false);
  const [openGettingStarted, setOpenGettingStarted] = useState(false);

  const handlePriceCalculatorClick = (event) => {
    if (window.innerWidth <= 1100) {
      setOpenPriceCalculator(!openPriceCalculator);
      event.preventDefault();
    }
  }

  const handleRequestDemoClick = (event) => {
    if (window.innerWidth <= 1100) {
      setOpenRequestDemo(!openRequestDemo);
      event.preventDefault();
    }
  }

  const handleGettingStartedClick = (event) => {
    if (window.innerWidth <= 1100) {
      setOpenGettingStarted(!openGettingStarted);
      event.preventDefault();
    }
  }

  return (
    <Layout>
      <SEO title="Manufacturing Biometric Solutions" />
      <Banner>
        <p>
          Are you looking for an immediate price estimate?&nbsp;<Link to="/pricing" onClick={handlePriceCalculatorClick}>Price Calculator</Link>
        </p>
      </Banner>
      <Hero
        handleRequestDemoClick={handleRequestDemoClick}
        handleGettingStartedClick={handleGettingStartedClick}
        statement="Does your plant require"
        punchlines={["Time and Attendance?", "Access Control?", "Turnstiles?", "Shift Control?", "Payroll Integration?"]}
        caption="We provide cloud-based Access Control as well as Time and Attendance solutions which rely on fingerprint or facial recognition. Track your employees' attendance against configurable work rules and integrate directly into your payroll provider."
      ></Hero>
      <TrustSection />
      <HeadingGroup heading="Biometric Attendance Tracking for Manufacturing">
        <p>
          Beyond Attendance fulfils multiple requirements within a single platform for the Manufacturing industry.
        </p>
        <p>The Time and Attendance function streamlines and automates payrolls while eliminating potential human error.</p>
        <p>
          Access to any number of designated areas on your business premises can be tightly controlled and restricted with our biometric system in conjunction with turnstiles, magnetic doors locks and vehicle gates by using facial and fingerprint recognition to deny access to any unauthorised people.
        </p>
        <p>
          Highly customisable work rules and patterns ensure that even the most complex working environments are catered for. Our Shift Sense Function enables employee’s to automatically be assigned to shifts based off their first and last scan of the day.
        </p>
        <p>
          Our automated reporting also enables line managers to receive daily reports on their employees' attendance allowing for rapid adjustments and responses in the event of unforeseen staff absenteeism.
        </p>
      </HeadingGroup>
      <PageBreak>Take a look at some of our key features below&nbsp;<span role="img" aria-label="fire">🔥</span></PageBreak>

      <ImageGroup reverse={false} image="../images/pricing.svg" heading="Competitive Pricing">
        <p>
          Our product has a scaled pricing model which allows you to choose the Time and Attendance tracking package that best suits your needs and offers maximum value for money.  The system is a month to month contract so you are not locked in by fixed terms and your monthly bill will depend on the number of employees on the system.
        </p>
        <ButtonContainer>
          <PrimaryButton to="/pricing" onClick={handlePriceCalculatorClick} padding="0.8rem" margin="0.8rem 0.8rem 0.8rem 0" fontSize="1rem">Price Calculator</PrimaryButton>
        </ButtonContainer>
      </ImageGroup>

      <ImageGroup reverse={true} image="../images/access.svg" heading="Access Control">
        <p>
          Movement within specific areas or locations can be tightly controlled and restricted using our biometric system in conjunction with turnstiles, magnetic door locks and vehicle gates. Facial and fingerprint recognition reliably and securely denies access to unauthorised people. Access control can also be an invaluable tool in ensuring people don’t abuse toilet and restroom breaks.
        </p>
      </ImageGroup>

      <ImageGroup reverse={false} image="../images/configurable.svg" heading="Overtime Management">
        <p>
          Beyond Attendance includes an overtime management system designed to digitize and simplify the overtime approval process. Overtime can be managed directly by an admin staff member or decentralised to allow production managers to control the overtime for their teams and divisions with admin staff members taking on a global oversite role. The function has a built in audit to ensure overtime issues can be identified and traced accordingly.
        </p>
      </ImageGroup>

      <ImageGroup reverse={true} image="../images/configurable.svg" heading="Configurable Shift Rules">
        <p>
          Work rules such as automatic deduction of lunch and tea breaks, removal of duplicated scans and automated rounding of start and end times, can be customized to suit each company uniquely. Using Machine Learning Technology, our system's Shift Sense Function will automatically detect and assign shifts based on employees' start and end scans.
        </p>
      </ImageGroup>

      <ImageGroup reverse={false} image="../images/biometric.svg" heading="Multiple Biometric Options">
        <p>
          You will receive full support for a wide range of biometric devices. Available options include fingerprint and facial recognition, blood vessel mapping, thermal temperature detection and palm scanning technologies. Additional options for smaller teams include USB based units that can simply be linked directly into a Microsoft Windows PC or Android Device through which people can scan to clock their activity during the day or night.
        </p>
      </ImageGroup>

      <ImageGroup reverse={true} image="../images/configurable.svg" heading="Employee Leave Management">
        <p>
          Beyond Attendance includes a leave management system for tracking and maintaining leave records. The system allows you to create and modify leave codes and types depending on your requirements. In addition to the basics, Beyond Attendance also allows for online document storage for items such as sick notes and letters of warning. These leave records can then be exported into your payroll system when exporting your payroll files.
        </p>
      </ImageGroup>

      <GetStartedSection
        handleRequestDemoClick={handleRequestDemoClick}
        handleGettingStartedClick={handleGettingStartedClick} />

      <RequestDemoDrawer open={openRequestDemo} handleClose={() => setOpenRequestDemo(false)} />
      <SignUpDrawer open={openGettingStarted} handleClose={() => setOpenGettingStarted(false)} />
      <PricingDrawer open={openPriceCalculator} handleClose={() => setOpenPriceCalculator(false)} />
    </Layout>
  )
}

export default ManufacturingPage
